/* @import '~antd/dist/antd.css'; */

/* Font sizes */
.fs-b-12{
  font-size: 12px;
  color: black;
}
.fs-b-25  {
  font-size: 25px;
  color: black;
}
.fs-r-18
{
  font-size: 12px;
  color: red;
}
.fs-b-32{
font-size: 32px;
color: #000;
}
.fs-g-32{
  color: rgb(0, 200, 137);
/* font-weight: bold; */
font-size: 32px;
}
.fs-b-20{
  font-size: 20px;
  color: black;
}
.fs-g-40{
  color: rgb(0, 200, 137);
font-weight: bold;
font-size: 40px;
}
.fs-g-25  {
  font-size: 25px;
  color: #00C889;
}
.fs-g-20{
  font-size: 20px;
  color: #00C889;
}


/* backgorund */
.lightBlueCard{
  background-color:  #00C889;
  border-radius: 12px;
}
.lightBlueCard h5{
color: #fff;
}
/* .lightBlueCard p{
  color: #fff;
  } */
  .leftMenu, .ant-menu{
color: #fff;
  }
  .leftMenu ,.ant-menu{
    background-color: #2b2b2b;
  }
  .leftMenu ,.ant-menu{
    background-color: #2b2b2b;
  }
.btn-green{
  border-radius: 15px !important;
  background-color: #00C889;
  font-weight: 600;
  color: #fff;
  height: 45px;
}
.btn-green:hover,.btn-green:focus{

  color: #00C889;
  border-color: #00C889;
  background: #fff;
}
.btn-offWhite{
  border-radius: 15px !important;
  background-color: #e8edeb;
  font-weight: 600;
  color: rgb(0, 0, 0);
  height: 45px;
}
.btn-offWhite:hover,.btn-offWhite:focus{

  color: #000;
  border-color: #000;
  background: #fff;
}
.bg-white{
background-color: #fff !important;
border-radius: 12px;
border: 1px solid rgba(0, 0, 0, 0.08);
box-shadow: 0 0 10px 0 rgba(0,0,0,.1);

/* padding: 20px; */
}
img{
  width: 100%;
}
/* Navbar */
.ant-upload.ant-upload-drag{
  height: auto;
}
.navbar {
  padding-bottom: 1px;
  border-bottom: solid 1px #e8e8e8;
  background-color: #2b2b2b;
  box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.12),
    0 6px 16px -11px rgba(0, 0, 0, 0.08);
  position: sticky;
  top: 0;
  /* margin-bottom: 20px; */
  z-index: 99990;
}
input{
  border-radius: 12px !important;
  padding: 10px !important;
}

.nav-header {
  background-color: #2b2b2b;
  border-bottom: 0;
  display: flex;
  align-items: center;
  padding: 0 4px 0 24px;
  width: 80% ;
  margin: auto;
}

.logo {
  width: 230px;
  /* height: 100%; */
  /* background-color: red; */
  float: left;
}
.logo img{
  height: 100%;
}

/* .logo a {
  display: inline-block;
  font-size: 20px;
  text-transform: capitalize;
} */

.navbar-menu {
  /* width: calc(100% - 250px); */
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
}
.navbar-menu .ant-layout-header {
  padding: 0 20px;

}


.navbar-menu .ant-menu-horizontal {
  border-bottom: 0;
}

.navbar-menu .ant-menu-item {
  padding-bottom: 0px;
}

.navbar-menu .leftMenu {
  float: right;
  width: 100%;
}

.navbar-menu .rightMenu {
  float: right;
}
.ant-drawer-header-title button{
  color: #fff;
}
.ant-drawer-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #2b2b2b;
  pointer-events: auto;
}
.menuButton {
  float: right;
  /* height: 32px; */
  /* padding: 6px; */
  color: #fff;
  cursor: pointer;
  /* margin-top: 14px; */
  display: none;
  background: none;
  /* margin-right: 10px; */
}
.ant-avatar.ant-avatar-icon {
  font-size: 0px;
}
.ant-drawer-body {
  padding: 0;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 767px) {
  .menuButton {
    display: block;
    margin-right: 10px;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }
  .navbar-menu {
    width: calc(100% - 200px);
    float: left;
    display: flex;
    justify-content: flex-end;
  }
  .logo a {
    margin-left: -10px;
  }

  .ant-drawer-title > .brand-font {
    margin-bottom: 0;
    width: 100%;
  }

  .username {
    display: inline-block;
  }
}
.miniLogo{
  height: 250px;
  max-width: 350px;
}



.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #00C889;
  
}
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: #00C889;
  box-shadow: 0 0 0 2px rgb(150 247 218 / 20%);
  border-right-width: 1px;
  outline: 0;
}
.ant-notification-notice{

  z-index: 9999 !important;
}
.ant-notification{
  z-index: 999999 !important;

}
.lighrbg{
  background-color: #f9f9f9;
  padding: 20px;
}
.anticon {
  cursor: pointer;
}
.removeicon{
  color: #FF0000;
}
.carasoulebg{
  background: rgb(238,174,202);
background: radial-gradient(circle, rgba(174, 238, 181,0.2) 0%, rgba(148,187,233,0.7) 100%);
min-height: calc(100vh - 90px);
display: flex !important;
align-items: center;
}
.offwhite{
 background-color:  #FAF9F6
}
/* .anticon{position: relative;
top: -4px;} */

.ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: lightgray !important;
  font-weight:bold ;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid lightgray;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: lightgray;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid lightgray;
}
.card {
  cursor: pointer;
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  /* width: 300px; */
  transition: all 0.5s;
}
.card:hover{
  scale: 1.03;
  position: relative;
  z-index: 999;
}

a{
  color: #000;
  cursor: pointer;
}
a:hover{
  /* text-decoration: none; */
  color: inherit;
}
.questionNotice{
  background: linear-gradient(90.04deg, #4be7b5 0.03%, #d0f1e0 99.96%);
  
  border-radius: 8px;
  padding: 20px;
}a:visited {
  color: inherit;
}


/*Sign In*/
/* .Login-Svg{
  margin: 0 auto;
  width: 300px;
  height: 100%;
} */

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  
  height: auto;
  border-radius: 12px;
  padding: 5px 10px;
}

.overFlowdots{
  display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}




.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #2b2b2b;
}
.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  color: #333333;
  padding: 50px;
}

.hero-content {
  max-width: 50%;
  padding-right: 50px;
}

.hero-image {
  max-width: 50%;
}
.lightGrayBg{
  background-color: gray;
}
.slick-slider {
  height: 100%;
}

.slick-dots {
  bottom: 20px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #ffffff;
}
.carousel-content h1{
  color: #fff;

}
.carousel-content p{
  color: #fff;
  line-height: 30px;
  font-size: 20px;
}
.ant-btn {
  background-color: #000;
  color: #ffff;
  border-radius: 8px;
  font-size: 20px;
height: auto !important;
margin-bottom: 30px;

}
.heroSection{
  /* height: 90vh; */
}
.masterplan-container {
  padding: 80px 0;
  background-color: #f5f5f5;
}

.masterplan-container h2 {
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 36px;
  color: #222;
}

.masterplan-img-container {
  overflow: hidden;
}

.masterplan-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.masterplan-img-container:hover img {
  transform: scale(1.1);
}

.masterplan-text-container p {
  font-size: 18px;
  line-height: 1.8;
  color: #444;
}

@media screen and (max-width: 767px) {
  .masterplan-container {
    padding: 50px 0;
  }

  .masterplan-container h2 {
    font-size: 28px;
  }

  .masterplan-text-container p {
    font-size: 16px;
  }
}
.location-section {
  background-color: #f8f8f8;
  padding: 80px 0;
}

.location-section .container {
  max-width: 1200px;
  margin: 0 auto;
}

.location-section h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 24px;
}

.location-section p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 16px;
}

.location-section img {
  width: 100%;
  height: auto;
}

#careers {
  background-color: #f8f9fa;
  margin-bottom: 50px;
  /* padding-bottom: 100px; */
}

#careers h2 {
  font-size: 3rem;
}

#careers p {
  font-size: 1.25rem;
}

#careers .btn {
  font-size: 1.25rem;
  font-weight: 500;
  color: #fff;
  background-color: #333;
  border-color: #333;
  padding: 0.75rem 2rem;
  border-radius: 8px;
  margin-top: 2rem;
  height: auto !important;
}
.footer {
  background-color: #2b2b2b;
  color: white;
  text-align: center;
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
}

.about-us-section {
  background-color: #f5f5f5;
  padding: 80px 0;
}

.about-us-heading {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 60px;
}

.about-us-text {
  font-size: 18px;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
}

#our-vision {
  background-color: #F2F2F2;
  padding: 80px 0;
}

.vision-content h2 {
  color: #000;
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 30px;
}

.vision-content p {
  color: #333;
  font-size: 18px;
  margin-bottom: 20px;
}

.vision-content img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 992px) {
  .vision-content {
    margin-top: 80px;
  }
  .vision-content img {
    margin-top: -80px;
    margin-left: 80px;
    transition: all 0.5s ease-in-out;
  }
  .vision-content:hover img {
    transform: scale(1.1);
  }
}

.company-section {
  margin-top: 50px;
  margin-bottom: 50px;
}

.company-section h2 {
  color: #30475e;
  font-size: 32px;
  margin-bottom: 30px;
}

.company-section p {
  color: #485460;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.company-section ul {
  color: #485460;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.company-section li {
  list-style: disc;
  margin-left: 30px;
}
.faq-container {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.faq-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.ant-collapse {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ant-collapse-header {
  font-weight: bold;
  color: #2d2d2d;
  background-color: #f5f5f5;
  border-bottom: none;
  padding: 12px 24px;
}

.ant-collapse-header:hover {
  background-color: #e1e1e1;
}

.ant-collapse-item-active .ant-collapse-header {
  background-color: #d9d9d9;
}

.ant-collapse-content {
  padding: 24px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.refund-policy {
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 50px 20px;
}

.refund-policy ul {
  margin-left: 20px;
}

.refund-policy li {
  margin-bottom: 10px;
  font-size: 18px;
}
.refund-policy  p{
  font-size: 18px;

}
.paymentPlanTable {
  margin-top: 20px;
}

.planTypeColumn,
.downPaymentColumn,
.installmentPlanColumn,
.totalPriceColumn {
  font-weight: bold;
}
.applyNowColumn {
  text-align: center;
}

.contact-us-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
}

.contact-us-form {
  width: 50%;
}

.contact-us-form h2 {
  margin-bottom: 20px;
}

.contact-us-info {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.info-icon {
  font-size: 24px;
  margin-right: 10px;
}

.social-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.social-icon {
  font-size: 24px;
 
}

.contact-info{
  display: flex;
  gap: 20px;
  font-size: 18px;
}
.social-media-icons{
  display: flex;
  gap: 20px;
  font-size: 18px;


}
.pricing {
  padding: 50px 0;
}

.pricing-heading {
  text-align: center;
  margin-bottom: 50px;
}

.pricing-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  overflow: hidden;
}

.pricing-image {
  width: 100%;
  height: 620px;
  object-fit: scale-down;
}

.pricing-body {
  padding: 30px;
}

.pricing-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.pricing-text {
  font-size: 18px;
  margin-bottom: 20px;
}

.pricing-price {
  font-size: 36px;
  margin-bottom: 30px;
}

.apply-now-btn {
  margin-top: 20px;
}