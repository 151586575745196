
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
*{
  font-family: 'Roboto', sans-serif;

}
ul{
  padding: 0;
  margin: 0;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: "Roboto";
  -moz-osx-font-smoothing: "Roboto";
}
.app{
  height: 100%;

}

code {
  font-family: 'Roboto', sans-serif;

}
